import React, { useState } from "react";
import TenantsPage from "./TenantsPage";
import useFetchTenantsPageData from "./useFetchTenantsPageData";

export const PAGE_SIZE = 20;

const TenantsPageWithState = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const { tenants, meta, isFetching } = useFetchTenantsPageData({
    currentPage,
    pageSize: PAGE_SIZE,
  });

  return (
    <>
      <TenantsPage
        isFetching={isFetching}
        tenants={tenants}
        meta={meta}
        currentPage={currentPage}
        onChangeCurrentPage={(page) => setCurrentPage(parseInt(page, 10))}
      />
    </>
  );
};

export default TenantsPageWithState;
