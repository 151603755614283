import dotProp from "dot-prop-immutable";
import { roundTo } from "round-to";
import queryString from "query-string";

export const delay = (time, value) =>
  new Promise((resolve) => {
    setTimeout(resolve, time, value);
  });

export const parseQueryString = (search) => queryString.parse(search);

export const arrayToObject = (array, keyField) =>
  array.reduce((obj, item) => {
    const newObj = obj;
    newObj[item[keyField]] = item;
    return obj;
  }, {});

export const arrayToKeyValueObject = (array, keyField, valueField) =>
  array.reduce((obj, item) => {
    const newObj = obj;
    newObj[item[keyField]] = item[valueField];
    return { ...obj };
  }, {});

export const capitalizeFirstLetter = (input) =>
  typeof input === "string"
    ? input[0].toUpperCase() + input.substring(1)
    : input;

export const clientFilterOptions = (input, array, key = "translation") =>
  array.filter((i) => i[key].toLowerCase().includes(input.toLowerCase()));

export const removeEmptyValues = (field) =>
  field
    .map((value) => {
      if (Object.keys(value).some((key) => value[key] === null)) {
        return null;
      }
      return value;
    })
    .filter((value) => value !== null);

export const sum = (current, next) => current + next;

export const countWhere = (key) => (current, obj) => current + obj[key];

export const flatten = (a, b) => [...a, ...b];

export const unique = (array) => [...new Set(array)];

export const where =
  (...keys) =>
  (...values) =>
  (obj) =>
    keys.every((key) =>
      key.includes("!")
        ? !values.includes(obj[key.replace("!", "")])
        : values.includes(obj[key]),
    );

export const whereIsNot = (key) => (value) => (obj) => obj[key] !== value;

export const whereId = where("id");

export const whereIsNotId = whereIsNot("id");

export const whereValue = where("value");

export const whereName = where("name");

export const whereSlug = where("slug");

export const whereExternalApplication = where("externalApplication");

export const whereUnique = (key) => (currentObject, currentIndex, array) => {
  const value = dotProp.get(currentObject, key);
  if (typeof value === "undefined") {
    return true;
  }
  const foundIndex = array.findIndex(
    (object) => dotProp.get(object, key) === value,
  );
  return foundIndex === currentIndex;
};

export const removeFromCollection = (id) => (collection) =>
  collection.filter(whereIsNotId(id));

export const addToCollection = (obj) => (collection) => [...collection, obj];

export const replaceInCollection = (obj) => (collection) =>
  collection.map((i) => (i.id === obj.id ? obj : i));

export const sortBy = (key) => (a, b) => {
  if (a === null || b === null) {
    return -1;
  }
  const aComp = a[key];
  const bComp = b[key];
  if (aComp < bComp) {
    return -1;
  }
  if (aComp > bComp) {
    return 1;
  }
  return 0;
};

export const download = async (url, name) => {
  const a = document.createElement("a");
  a.download = name;
  a.href = url;
  a.style.display = "none";
  document.body.append(a);
  a.click();
  // Chrome requires the timeout
  await delay(100);
  a.remove();
};

export const downloadFromResponse = async (response) => {
  if (response.ok) {
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const fileExtension = "xls";
    let filename = `download-${new Date().toJSON()}.${fileExtension}`;
    const headers = response.headers.get("content-disposition");
    if (headers !== null) {
      const regEx = "filename\\*?=((['\\\"])[\\s\\S]*?\\2|[^;\\n]*)";
      // eslint-disable-next-line prefer-destructuring
      filename = headers.match(regEx)[1];
    }
    const a = document.createElement("a");
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    a.remove();
  }
};

export const downloadMultipleFiles = async (files) => {
  const downloadPromises = files.map(async ({ url, name }, index) => {
    await delay(index * 1000);
    download(url, name);
  });
  await Promise.all(downloadPromises);
};

export const validateEmail = (email) => {
  // eslint-disable-next-line no-useless-escape
  const regEx =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regEx.test(email);
};

export const toBoolean = (input) =>
  typeof input === "boolean" ? input : input.toLowerCase() === "true";

/**
 * Helper method for creating a range of numbers
 * range(1, 5) => [1, 2, 3, 4, 5]
 */
export const range = (from, to, step = 1) => {
  let i = from;
  const rangeArray = [];

  while (i <= to) {
    rangeArray.push(i);
    i += step;
  }

  return rangeArray;
};

export const arrayMove = (array, from, to) => {
  const newArray = [...array];
  const startIndex = to < 0 ? newArray.length + to : to;

  if (startIndex >= 0 && startIndex < newArray.length) {
    const item = newArray.splice(from, 1)[0];
    newArray.splice(startIndex, 0, item);
  }

  return newArray;
};

export const roundNumber = (value, digits = 0) => roundTo(value, digits);

export const isEmptyObject = (obj) => !(Object.keys(obj).length > 0);

export const groupBy = (items, key) =>
  items.reduce(
    (result, item) => ({
      ...result,
      [item[key]]: [...(result[item[key]] || []), item],
    }),
    {},
  );

export const hoursPerDay = [
  "00:00",
  "01:00",
  "02:00",
  "03:00",
  "04:00",
  "05:00",
  "06:00",
  "07:00",
  "08:00",
  "09:00",
  "10:00",
  "11:00",
  "12:00",
  "13:00",
  "14:00",
  "15:00",
  "16:00",
  "17:00",
  "18:00",
  "19:00",
  "20:00",
  "21:00",
  "22:00",
  "23:00",
];
